@import "src/scss/module";

.form {
  width: 100%;

  a {
    color: $primary;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}