@import "src/scss/module";

.form {

  label {


    &[class*=-required]:after {
      content: '*';
      color: $primary
    }

    &[class*=--hidden] {
      display: none;
    }

    a {
      color: $primary
    }
  }

  [class*=formio-component-file] {
    margin-bottom: .75em;
  }


  ::placeholder {
    color: #666;
  }

  [ref="element"] {
    margin-bottom: .75em;
  }

  [class*="formio-component-file"] {
    display: flex;
    flex-direction: column;
    height: 100%;

    [class*="list-group"] {
      &:only-child {
        display: none;
      }
    }

    [ref="fileDrop"] {
      background-color: $white;
      border: 1px solid #B4ADAD;
      padding: 1rem;
      text-align: center;
      font-weight: $font-weight-bold;
      font-size: $font-size-sm;
      color: $dark;
      margin-bottom: 1em;

      a {
        color: inherit !important;
      }
    }
  }

  textarea,
  input {
    border-radius: 0;
  }

  [ref="radioGroup"] {
    margin-bottom: .75em;
  }

  [class*=formio-component-submit] {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    button {
      order: 1;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;

      button {
        order: 3;
      }
    }

    [ref="buttonMessageContainer"] {
      order: 2;
      color: $primary;
      font-size: small;
      text-align: center;
    }
  }

  :global {
    .form-check-input:checked {
      background-color: $primary;
      border-color: $primary;
    }

    .alert.alert-danger {
      font-size: .9rem;
      margin-bottom: 0;
      padding: 0;

      p {
        margin-bottom: 0;
      }
    }
  }
}
